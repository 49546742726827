import React from 'react';
import {useMatch, useNavigate} from 'react-router-dom';
import {useStore} from '../../store/valtio';
import actions from '../../store/valtio/actions';
import {ListItemButton, ListItemIcon, ListItemText, Theme, useMediaQuery} from '@mui/material';
import {Home} from '@mui/icons-material';

export type SidebarLinkProps = {
  path?: string;
  icon?: React.ReactNode;
  text?: string;
  itemKey?: any;
  exact?: boolean;
  external?: boolean;
}

const SidebarLink = ({path = '/', icon = <Home/>, text ='Link', itemKey, exact=false, external=false}: SidebarLinkProps) => {
  const {app} = useStore();
  // @ts-ignore
  const navigate = useNavigate();
  const matches = useMatch({path, end: exact});
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const clickHandler = () => {
    if (external) {
      window.location.href = path;
    }
    else {
      navigate(path);
      if (!isDesktop) {
        actions.app.setSideBarOpen(false);
      }
    }
  };

  return (
    <ListItemButton key={itemKey} onClick={clickHandler} sx={matches ? styles.activeButton : styles.button} focusRipple>
      <ListItemIcon sx={styles.icon} children={icon} />
      <ListItemText primary={app.sideBarOpen ? text : text.split(' ')[0]} />
    </ListItemButton>
  );
};

const styles = {
  icon: {
    color: (theme: Theme) => theme.palette.secondary.contrastText,
    minWidth: 'unset',
    width: '1.5rem',
    marginRight: (theme: Theme) => theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeButton: {
    backgroundColor: (theme: Theme) => theme.palette.secondary.light,
    borderLeft: '0.250rem solid',
    cursor: 'pointer',
    borderLeftColor: (theme: Theme) => theme.palette.primary.main,
    paddingLeft: (theme: Theme) => (theme.spacing(1) as unknown as number) * 1.5,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: (theme: Theme) => theme.palette.secondary.light
    }
  },
};

export default SidebarLink;
