// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {useDeleteOfficeMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, Typography, Button, Box, DialogActions, DialogContent} from "@mui/material";
import {Cancel} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

const DeleteOfficeDialog = ({office, open, onClose, ...rest}) => {

  const [deleteOffice, {data, error, isLoading}] = useDeleteOfficeMutation();

  return (
    <>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Cancel color={'error'}/>
          <Typography variant='h2' color={'error'} sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>
            {t`Remove Office`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t`Do you really want to remove this office?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box display='flex' mx={1} gap={2}>
            <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
            <Button variant={'contained'} color={'error'} onClick={() => deleteOffice(office).then(r => {
              if (!r.error) {
                enqueueSnackbar(t`The office` + ` ${office?.officeCode} ` + t`was successfully removed!`, {variant: 'success'});
                onClose();
              }})
            }>
                {t`Delete`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteOfficeDialog.propTypes = {
  office: PropTypes.shape({
    officeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteOfficeDialog.defaultProps = {
  office: null,
  open: true,
  onClose: null,
};

export default DeleteOfficeDialog;
