import React from 'react';
import {Box, Card, CardContent, CardHeader, Divider, LinearProgress, Typography} from '@mui/material';
import {t} from 'ttag';
import {createDatetime, DATETIME_FORMAT_NO_SEC} from '../../../utils/datetime/datetime-utils';
import {DataGrid, GridSlotsComponent} from '@mui/x-data-grid';
import table from '../../../components/common/styles';
import {IFlight} from '../../../store/apis/interfaces/claim';

type FlightsDetailsProps = {
  rows?: IFlight[];
};

const columns = [
  {
    field: 'flightNumber',
    headerName: t`Flight Number`,
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 170,
    cellClassName: 'secondary-text',
    valueGetter: ({row}: any) => row?.flightNumber || 'N/A'
  },
  {
    field: 'flightDate',
    headerName: t`Date`,
    sortable: true,
    type: 'date',
    disableColumnMenu: true,
    flex: 1,
    minWidth: 140,
    valueGetter: ({row}: any) => new Date(row?.flightDate),
    renderCell: ({row}: any) => row?.flightDate ? createDatetime(row?.flightDate).toFormat(DATETIME_FORMAT_NO_SEC) : 'N/A'
  },
  {
    field: 'originAirport',
    headerName: t`Origin Airport`,
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 170,
    valueGetter: ({row}: any) => row?.originAirport?.iataCode || 'N/A'
  },
  {
    field: 'destinationAirport',
    headerName: t`Destination Airport`,
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 170, valueGetter: ({row}: any) => row?.destinationAirport?.iataCode || 'N/A'
  },
  {
    field: 'type',
    headerName: t`Type`,
    sortable: true,
    disableColumnMenu: true,
    minWidth: 190,
    flex: 1,
    valueGetter: ({row}: any) => row?.type || 'N/A'
  },
  {
    field: 'subType',
    headerName: t`Subtype`,
    sortable: true,
    disableColumnMenu: true,
    minWidth: 190,
    flex: 1,
    valueGetter: ({row}: any) => row?.subType || 'N/A'
  }
];

export const FlightsDetails = ({rows}: FlightsDetailsProps) => {

  return (
    <Card elevation={3}>
      <CardHeader title={<Typography sx={{fontFamily: 'OpenSansMedium'}}>{t`Flights Details`}</Typography>} />
      <Divider />
      <CardContent>
        <DataGrid
          hideFooter
          getRowId={(row) => row?.flightId}
          columns={columns}
          rows={rows || []}
          loading={Boolean(!rows)}
          autoHeight
          components={{
            noRowsOverlay: () => (
              <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant={'body2'}>{t`No flights found`}</Typography>
              </Box>
            ),
            loadingOverlay: () => (
              <LinearProgress sx={{
                backgroundColor: 'rgb(228,232,238)',
                height: 6,
                '& .MuiLinearProgress-bar1Indeterminate': {
                  backgroundColor: 'rgb(228,232,238)',
                },
                '& .MuiLinearProgress-bar2Indeterminate': {
                  backgroundColor: 'rgb(218,222,228)',
                }
              }}/>
            )
          } as Partial<GridSlotsComponent>}
          sx={{
            ...table,
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal !important'
            },
            '& .MuiDataGrid-columnHeaders': {
                ...table['& .MuiDataGrid-columnHeaders'],
                borderBottom: Boolean(!rows) ? '1px solid transparent' : '1px solid #0e2846'
            }
          }}
        />
      </CardContent>
    </Card>
  );
}

export default FlightsDetails;
