// @ts-nocheck
import React, {useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {columns} from './refundSubjectFormsOfPaymentColumns';
import ConfirmDialog from '@components/common/ConfirmDialog';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, Divider, IconButton, Typography} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useFormContext, useWatch} from 'react-hook-form';
import table from '../../../../../../../../../../../components/common/styles';

export const EMPTY_FORM_OF_PAYMENT = {localId: -1, indicator: 'CA', value: 0, currentValue: 0, active: true, modified: true};

const RefundSubjectFormsOfPayment = ({editable}) => {

  const {setValue, trigger} = useFormContext();
  const [currencyCode, paymentOptions, paymentOptionsOriginal] = useWatch({name: ['currencyCode', 'paymentOptions', 'original.paymentOptions']});
  const [formOfPaymentToDelete, setFormOfPaymentToDelete] = useState(null);
  const triggerTotalRefundValidation = () => trigger('totalRefund');

  const createHandler = () => {
    const newFormOfPayment = {...EMPTY_FORM_OF_PAYMENT};
    newFormOfPayment.localId = paymentOptions?.length || -1;
    newFormOfPayment.currency = currencyCode;
    setValue('paymentOptions', paymentOptions.concat([newFormOfPayment]), {shouldValidate: true});
    triggerTotalRefundValidation().then();
  }

  const editHandler = ({field, value, id}) => {
    const original = paymentOptionsOriginal.find(({localId}) => localId === id);
    const modified = Boolean(!original || original?.[field] !== value);
    setValue('paymentOptions', paymentOptions.map(po => po.localId === id ? {...po, [field]: value, modified} : po), {shouldValidate: true});
    triggerTotalRefundValidation().then();
  }
  const removeHandler = () => {
    const {localId: id} = formOfPaymentToDelete;
    const original = paymentOptionsOriginal.find(({localId}) => localId === id);
    if (original) {
      setValue('paymentOptions', paymentOptions.map(po => po.localId === id ? {...po, active: false, modified: true} : po), {shouldValidate: true});
    } else {
      setValue('paymentOptions', paymentOptions.filter(po => po.localId !== id), {shouldValidate: true});
    }
    setFormOfPaymentToDelete(null);
    triggerTotalRefundValidation().then();
  }

  const AddRowBtn = () => (
    <IconButton onClick={createHandler} size={'small'}>
      <PlaylistAddIcon/>
    </IconButton>
  );

  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography fontFamily={'OpenSansMedium'}>{t`Forms of payment`}</Typography>}
        action={editable && <AddRowBtn/>}
      />
      <Divider/>
      <CardContent>
        <DataGrid
          autoHeight
          density={'compact'}
          editMode={'cell'}
          getRowId={({localId}) => localId}
          columns={columns({
            removeHandler: setFormOfPaymentToDelete,
            disableDeleteBtn: paymentOptions?.length <= 1,
            editHandler,
            editable
          })}
          rows={paymentOptions?.filter(({active}) => active) || []}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableRowSelectionOnClick
          hideFooter
          sx={table}
        />
        {Boolean(formOfPaymentToDelete) && (
          <ConfirmDialog
            title={t`Form of payment`}
            children={t`Do you really want to remove this form of payment?`}
            cancelText={t`Cancel`}
            confirmText={t`Delete`}
            variant={'error'}
            onConfirm={removeHandler}
            onCancel={() => setFormOfPaymentToDelete(null)}
            reverse
          />
        )}
      </CardContent>
    </Card>
  );
};

RefundSubjectFormsOfPayment.propTypes = {
  editable: PropTypes.bool,
};

RefundSubjectFormsOfPayment.defaultProps = {
  editable: true,
};

export default RefundSubjectFormsOfPayment;
