import React from 'react';
import {Box, Card, CardContent, CardHeader, Divider, LinearProgress, Link, Typography} from '@mui/material';
import {t} from 'ttag';
import {createDatetime, DATETIME_FORMAT_NO_SEC} from '../../../utils/datetime/datetime-utils';
import {DataGrid, GridSlotsComponent} from '@mui/x-data-grid';
import table from '../../../components/common/styles';
import {IClaimIncident} from '../../../store/apis/interfaces/claim';

type RelatedIncidentsProps = {
  title: string;
  rows?: IClaimIncident[];
};

const columns = [
  {
    field: 'createdOn',
    headerName: t`Date`,
    sortable: true,
    type: 'date',
    disableColumnMenu: true,
    flex: 1,
    minWidth: 140,
    valueGetter: ({row}: any) => new Date(row.createdOn),
    renderCell: ({row}: any) => createDatetime(row.createdOn).toFormat(DATETIME_FORMAT_NO_SEC)
  },
  {
    field: 'ticketNumber',
    headerName: t`Process Number`,
    sortable: true,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 170,
    cellClassName: 'secondary-text',
    renderCell: ({row}: any) =>
        <Link href={`/claims?process=${row.ticketNumber}`}>
          {row.ticketNumber}
        </Link>
  },
  {
    field: 'claimStatus.name',
    headerName: t`Status`,
    sortable: true,
    disableColumnMenu: true,
    minWidth: 220,
    flex: 1,
    cellClassName: 'bold',
    renderCell: ({row}: any) =>
      <Box>
        <Typography variant={'body2'} color={'secondary'} fontFamily={'OpenSansMedium'}>
          {row.claimStatus.state[1]}
        </Typography>
        <Typography variant={'body2'}>
          {`(${row.claimStatus.name})`}
        </Typography>
      </Box>
  },
  {
    field: 'paymentStatus.name',
    headerName: t`Payment Status`,
    sortable: true,
    disableColumnMenu: true,
    minWidth: 190,
    flex: 1,
    renderCell: ({row}: any) => row?.paymentStatus?.name || 'N/A'
  }
];

export const RelatedIncidents = ({title, rows}: RelatedIncidentsProps) => {

  return (
    <Card elevation={3}>
      <CardHeader title={<Typography sx={{fontFamily: 'OpenSansMedium'}}>{title}</Typography>} />
      <Divider />
      <CardContent>
        <DataGrid
          hideFooter
          getRowId={(row) => row?.incidentId}
          columns={columns}
          rows={rows || []}
          loading={Boolean(!rows)}
          autoHeight
          components={{
            noRowsOverlay: () => (
              <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant={'body2'}>{t`No incidents found`}</Typography>
              </Box>
            ),
            loadingOverlay: () => (
              <LinearProgress sx={{
                backgroundColor: 'rgb(228,232,238)',
                height: 6,
                '& .MuiLinearProgress-bar1Indeterminate': {
                  backgroundColor: 'rgb(228,232,238)',
                },
                '& .MuiLinearProgress-bar2Indeterminate': {
                  backgroundColor: 'rgb(218,222,228)',
                }
              }}/>
            )
          } as Partial<GridSlotsComponent>}
          sx={{
            ...table,
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal !important'
            },
            '& .MuiDataGrid-columnHeaders': {
                ...table['& .MuiDataGrid-columnHeaders'],
                borderBottom: Boolean(!rows) ? '1px solid transparent' : '1px solid #0e2846'
            }
          }}
        />
      </CardContent>
    </Card>
  );
}

export default RelatedIncidents;
