// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import {DeleteOutlined, Launch} from '@mui/icons-material';
import {DateTime} from 'luxon';
import Status from '@components/status';

export const columns = ({viewHandler, removeHandler}) => [
  {
    field: 'processId',
    headerName: t`Process ID`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 130,
    cellClassName: 'secondary-text'
  },
  {
    field: 'documentNumber',
    headerName: t`Refund ID`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 130,
    cellClassName: 'secondary-text'
  },
  {
    field: 'agent',
    headerName: t`Agent`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    renderCell: (params) => (
      <Tooltip title={params?.row?.agent}>
        <Typography variant={'body2'} sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'noWrap'}}>
          {params?.row?.agent || '-'}
        </Typography>
      </Tooltip>
    )
  },
  {
    field: 'createdOn',
    headerName: t`Processed On`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      return `${params?.row?.createdOn?.split(':')?.slice(0, 2)?.join(':') || '-'}`;
    }
  },
  {
    field: 'state',
    headerName: t`Status`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      return <Status state={params?.row?.state} size={'small'} />;
    },
    minWidth: 135
  },
  {
    field: 'total',
    type: 'number',
    headerName: t`Total`,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 2 / 3,
    valueGetter: (params) => {
      return `${params?.row?.total.toFixed(2)} ${params?.row?.currency}`;
    },
    cellClassName: 'bold',
    minWidth: 125
  },
  {
    field: 'actions',
    headerName: t`Actions`,
    type: 'number',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const diff = DateTime.now().diff(DateTime.fromFormat(params.row.createdOn, 'yyyy-MM-dd HH:mm:ss'), [
        'days',
        'hours'
      ]);
      const {days, hours} = diff;
      return (
        <Box>
          <IconButton onClick={() => viewHandler(params.row)} aria-label='edit' size={'medium'} color='primary'>
            <Launch fontSize='small' />
          </IconButton>
          {days === 0 && hours < 24 && (
            <IconButton
              onClick={() => removeHandler(params.row)}
              size={'medium'}
              aria-label='delete'
              color={'secondary'}
            >
              <DeleteOutlined fontSize='inherit' />
            </IconButton>
          )}
        </Box>
      );
    }
  }
];

export const helper = {
  columns
};

export default helper;
