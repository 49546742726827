//@ts-nocheck
import React, {useEffect, useMemo, useState} from 'react';
import {t} from 'ttag';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, FormProvider} from 'react-hook-form';
import {
    Box,
    Button,
    CardContent,
    CardHeader,
    Divider,
    Paper,
    Alert,
    Typography,
    useMediaQuery,
    Collapse, Stack
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {SaveOutlined} from '@mui/icons-material';
import {Theme} from '@mui/system';
import {
  transportFacilityValidationSchema
} from "./transport-facilities-form/schemas/transportFacilityValidationSchema";
import TransportFacilityForm from './transport-facilities-form/TransportFacilityForm';
import {
  useGetCompaniesQuery,
  useGetTransportFacilitiesMotivesQuery,
  useGetTransportFacilitiesPerEmployeeMutation, usePutTransportFacilityRequestMutation
} from '../../api';
import {useGetCurrentUserQuery} from '../../../store/apis/ccs';
import {createDatetime as dt, DATETIME_FORMAT, nowTz} from '../../../utils/datetime/datetime-utils';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import {enqueueSnackbar} from 'notistack';

const CreateTransportFacility = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError
  } = useGetCompaniesQuery({page: 0, rows: 100});
  const {data: user, isLoading: userLoading, error: userError} = useGetCurrentUserQuery({}) as any;
  const {
    data: motives,
    isLoading: motivesLoading,
    error: motivesError
  } = useGetTransportFacilitiesMotivesQuery({page: 0, rows: 100});
  const [getBeneficiary, {data: beneficiary, isLoading: beneficiaryLoading, error: beneficiaryError}] =
    useGetTransportFacilitiesPerEmployeeMutation();
  const [onPutRequest, {error: putRequestError, isLoading: putRequestIsLoading}] =
    usePutTransportFacilityRequestMutation();
  const [selectedFacilityId, setSelectedFacilityId] = useState(null);

  const methods = useForm({
    resolver: yupResolver(transportFacilityValidationSchema),
    defaultValues: {
      colaborator: undefined as any,
      travelDate: dt(nowTz(undefined, DATETIME_FORMAT)).plus({days: 1}).toFormat(DATETIME_FORMAT),
      travelCompany: '',
      isColdVacation: false,
      authorizations: [] as any,
      motive: '',
      observations: ''
    }
  });

  const travelCompany = methods.watch('travelCompany');
  const travelDate = methods.watch('travelDate').slice(0, 10);
  const includeIsColdVacation = methods.watch('isColdVacation');
  const colaborator = methods.watch('colaborator')?.employeeId;

  useMemo(() => {
    if (user && companies) {
      getBeneficiary({
        page: 0,
        rows: 100,
        travelInCompanyId: travelCompany || '',
        travelDate: travelDate,
        includeIsColdVacation: includeIsColdVacation,
        collaboratorSelectedFacilityTypeId: selectedFacilityId || null,
        employeeId:
          colaborator ||
          (
            sessionStorage.getItem('selectedEmployeeId') &&
            JSON.parse(sessionStorage.getItem('selectedEmployeeId') || '{}')
          )?.employeeId ||
          user?.employeeId
      });
    }
  }, [
    getBeneficiary,
    travelCompany,
    travelDate,
    includeIsColdVacation,
    colaborator,
    companies,
    user,
    selectedFacilityId
  ]);

  useEffect(() => {
    if (beneficiary && companies && user) {
      methods.reset({
        colaborator:
          methods.watch('colaborator') ||
          (sessionStorage.getItem('selectedEmployeeId') &&
            JSON.parse(sessionStorage.getItem('selectedEmployeeId') || '{}')) ||
          user,
        travelDate:
          methods.watch('travelDate') ||
          dt(nowTz(undefined, DATETIME_FORMAT)).plus({days: 1}).toFormat(DATETIME_FORMAT),
        travelCompany: methods.watch('travelCompany') || '',
        isColdVacation: methods.watch('isColdVacation') || false,
        motive: methods.watch('motive') || '',
        observations: methods.watch('observations') || '',
        authorizations: beneficiary.transportFacilitiesPerBeneficiaries
          .map((b) => {
            return {
              ...b,
              selectedFacilityId:
                b.beneficiaryFamilyMember?.familyMember.relationshipType === 'COLABORADOR'
                  ? selectedFacilityId
                  : undefined
            };
          })
          .map((b) => {
            return {
              ...b,
              new: b.beneficiaryFamilyMember?.familyMember.relationshipType === 'ACOMPANHANTE' ? false : undefined
            };
          })
      });
    }
  }, [beneficiary, methods, companies, user, selectedFacilityId]);

  const handleSubmit = methods.handleSubmit((values) => {
    const authorizations = values.authorizations
      ?.filter((a) => a.selectedFacilityId)
      ?.map((a) => ({
        facilityType: {id: a.selectedFacilityId},
        reservationMethod: {id: a.allowanceDefinitionUsage?.find((b: any) => b?.facilityType?.id === a.selectedFacilityId)?.reservationMethod?.id},
        beneficiary: a.beneficiaryFamilyMember?.beneficiary?.id
          ? {id: a.beneficiaryFamilyMember?.beneficiary?.id}
          : null,
        familyMember: a.beneficiaryFamilyMember?.familyMember
      }));
    authorizations.length === 0
      ? methods.setError('authorizations', {
          type: 'custom',
          message: t`You must select at least one type of transportation facility for a beneficiary`
        })
      : authorizations.find((a) => a.familyMember.name === null || a.familyMember.idNumber === null)
      ? methods.setError('authorizations', {
          type: 'custom',
          message: t`Fill in the necessary fields to save the companion`
        })
      : onPutRequest({
          employeeId: values.colaborator?.employeeId,
          travelDate: values.travelDate.slice(0, 10),
          observations: values.observations,
          travelCompany: {id: values.travelCompany},
          motive: {id: values.motive},
          isNonProfit: true,
          authorizations: authorizations
        }).then(
          // @ts-ignore
          (r) => {
            if (!r.error) {
              enqueueSnackbar(t`Transport facility request created successfully!`, {variant: 'success'});
              navigate('/transport-facilities')
            }
          }
        );
  });

  if (companiesLoading || motivesLoading || userLoading || !user) {
    if (companiesError || motivesError || userError) {
      navigate('/transport-facilities');
    }
    return <RTKQueryFeedback isLoading={true} error={companiesError || motivesError || userError} />;
  }

  return (
    <>
      <Box position={'relative'}>
        <RTKQueryFeedback
          error={beneficiaryError || putRequestError}
          isLoading={beneficiaryLoading || putRequestIsLoading}
        />
        <FormProvider {...methods}>
          <Paper elevation={3}>
            <CardHeader
              sx={{display: isMobile ? 'block' : 'flex'}}
              title={
                <Typography
                  sx={{fontFamily: 'OpenSansMedium', fontSize: {xs: '1.25rem', md: '1.5rem'}}}
                >{t`New Request`}</Typography>
              }
            />
            <Divider />
            <Collapse in={!beneficiary?.hasTransportFacilitiesEligibility && !beneficiaryLoading}>
              <Alert severity={'error'}>
                {t`The selected collaborator is ineligible to use the selected company's transportation facilities.`}
              </Alert>
            </Collapse>
            <CardContent>
              <TransportFacilityForm
                companies={companies?.content}
                motives={motives?.content}
                onSetSelectedFacilityId={(value: any) => setSelectedFacilityId(value)}
              />
            </CardContent>
            <Divider />
            <Box display={{xs: "box", md: 'flex'}} p={3} py={2} gap={1} justifyContent={'space-between'}>
              <Button
                color={'inherit'}
                variant={'outlined'}
                sx={{width: {xs: '100%', md: 'auto'}, mb: {xs: 2, md: 0}}}
                onClick={() => navigate('/transport-facilities')}
              >{t`Cancel`}</Button>
              <Stack direction={{ xs:"column", md:"row" }} gap={2}>
                <Button
                  startIcon={<SaveOutlined />}
                  variant={'contained'}
                  onClick={handleSubmit}
                  sx={{width: {xs: '100%', md: 'auto'}}}
                  disabled={!beneficiary?.hasTransportFacilitiesEligibility}
                >{t`Save`}</Button>
              </Stack>
            </Box>
          </Paper>
        </FormProvider>
      </Box>
    </>
  );
};

export default CreateTransportFacility;
