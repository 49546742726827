import {
  Box,
  List,
  Grid,
  Tooltip,
  ListItem,
  Checkbox,
  TextField,
  IconButton,
  Typography,
  CardHeader,
  ListItemText, Stack
} from '@mui/material';
import {t} from 'ttag';
import React from 'react';
import {Paper} from '@mui/material';
import {Controller} from 'react-hook-form';
import {DeleteOutlined} from '@mui/icons-material';
import BeneficiaryFacilityTypeSelector
  from '../../../../components/beneficiary-facility-type-selector/BeneficiaryFacilityTypeSelector';
import GenderSelector from '../../../../components/gender-selector/GenderSelector';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DATE_FORMAT} from '../../../../../utils/datetime/datetime-utils';

export const BeneficiariesListMobile = ({
  fields,
  selectionModel,
  setSelectionModel,
  setSelectedFacilityId,
  control,
  updateCompanion,
  deleteHandler,
  updateHandler,
  error,
  innerRef
}: any) => {
  return (
    <>
      <Grid container>
        {/*@ts-ignore*/}
        {fields.map((row: any, idx) => {
          return (
            <Paper key={idx} sx={{width: '100%', bgcolor: 'white'}} elevation={0}>
              <CardHeader
                ref={row.new && innerRef}
                sx={{bgcolor: '#F2F4F7', py: 1}}
                title={
                  row.new ? (
                    <Typography color={'secondary'} sx={{fontFamily: 'OpenSansMedium'}}>
                      {t`New Companion`}
                    </Typography>
                  ) : (
                    <Typography color={'secondary'} sx={{fontFamily: 'OpenSansMedium', textTransform: 'capitalize'}}>
                      {row?.beneficiaryFamilyMember?.familyMember?.name?.toLowerCase()}
                    </Typography>
                  )
                }
                action={
                  <Box>
                    {row.new && (
                      <IconButton aria-label={'delete'} onClick={() => deleteHandler(row)} size={'small'}>
                        <DeleteOutlined sx={{fontSize: 'larger'}} />
                      </IconButton>
                    )}
                    <Checkbox
                      value={selectionModel.includes(idx)}
                      checked={selectionModel.includes(idx)}
                      onClick={() => {
                        const unselectedRows = fields.filter(
                          (row: any) => !new Set(selectionModel.filter((id: number) => id !== idx)).has(fields.indexOf(row))
                        );
                        unselectedRows.map((row: any) => updateHandler({...row, selectedFacilityId: undefined}));
                        selectionModel.includes(idx)
                          ? (row?.beneficiaryFamilyMember?.familyMember.relationshipType === 'COLABORADOR' &&
                              setSelectedFacilityId(null)) ||
                            setSelectionModel([...selectionModel.filter((s: number) => s !== idx)])
                          : setSelectionModel([...selectionModel, idx]);
                      }}
                    />
                  </Box>
                }
              />
              <List sx={{padding: 0}} dense>
                {row.new && (
                  <ListItem sx={styles.odd}>
                    <ListItemText
                      sx={styles.listItem}
                      primary={
                        <>
                          <Typography variant={'body2'} sx={styles.title}>{t`Nome`}</Typography>
                          <Controller
                            name={'name'}
                            control={control}
                            render={({field: {value, onChange}, fieldState: {error}}) => (
                              <TextField
                                value={value}
                                onChange={(e: any) => {
                                  onChange(e.target.value);
                                  updateCompanion(row);
                                }}
                                fullWidth={true}
                                size={'small'}
                                color={'primary'}
                                error={Boolean(error)}
                              />
                            )}
                          />
                        </>
                      }
                    />
                  </ListItem>
                )}
                <ListItem sx={styles.odd}>
                  <ListItemText
                    sx={styles.listItem}
                    primary={<Typography sx={styles.title}>{t`Beneficiary`}</Typography>}
                  />
                  <ListItemText
                    sx={styles.listItem}
                    primary={
                      row.beneficiaryFamilyMember?.beneficiary?.name ? (
                        <Tooltip title={row.beneficiaryFamilyMember.beneficiary.name}>
                          <Typography variant={'body2'} align={'right'}>
                            {row.beneficiaryFamilyMember.beneficiary.name}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant={'body2'} align={'right'}>
                          {row.new || row.new === null ? t`Companion` : t`No match`}
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                {row.new && <ListItem sx={styles.odd}>
                    <ListItemText
                      sx={styles.listItem}
                      primary={
                        <>
                          <Typography variant={'body2'} sx={styles.title}>{t`Gender & Birthday`}</Typography>
                          <Stack direction={'row'} height={'34px'} spacing={1} >
                            <Controller
                              name={'gender'}
                              control={control}
                              render={({field, fieldState: {error}}) => (
                                <GenderSelector value={field.value} onChange={(value: string) => {
                                  field.onChange(value);
                                  updateCompanion(row);
                                }} error={error} />
                              )}
                            />
                            <Controller
                              name={'birthDate'}
                              control={control}
                              render={({field, fieldState: {error}}) => (
                                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={localStorage.getItem('LANG') === 'pt' ? 'PT-pt': 'EN-us'}>
                                  <DatePicker  disableFuture onChange={(value) => {
                                    field.onChange(value.toFormat(DATE_FORMAT));
                                    updateCompanion(row);
                                  }} inputFormat={'yyyy-MM-dd'} value={field.value} renderInput={(props) =>  <TextField {...props} label={t`Birthday`} size={'small'}/>}/>
                                </LocalizationProvider>
                              )}
                            />
                          </Stack>
                        </>
                      }
                    />
                </ListItem>}
                <ListItem sx={styles.odd}>
                  {row.new ? (
                    <ListItemText
                      sx={styles.listItem}
                      primary={
                        <>
                          <Typography variant={'body2'} sx={styles.title}>{t`Identification Number`}</Typography>
                          <Controller
                            name={'idNumber'}
                            control={control}
                            render={({field: {value, onChange}, fieldState: {error}}) => (
                              <TextField
                                value={value}
                                onChange={(e: any) => {
                                  onChange(e.target.value);
                                  updateCompanion(row);
                                }}
                                fullWidth={true}
                                size={'small'}
                                color={'primary'}
                                error={Boolean(error)}
                              />
                            )}
                          />
                        </>
                      }
                    />
                  ) : (
                    <>
                      <ListItemText
                        sx={styles.listItem}
                        primary={<Typography sx={styles.title}>{t`Identification Number`}</Typography>}
                      />
                      <ListItemText
                        sx={styles.listItem}
                        primary={
                          <Typography variant={'body2'} align={'right'}>
                            {row.beneficiaryFamilyMember.familyMember.idNumber}
                          </Typography>
                        }
                      />
                    </>
                  )}
                </ListItem>
                <ListItem>
                  <ListItemText
                    sx={styles.listItem}
                    primary={
                      <>
                        <Typography variant={'body2'} sx={styles.title}>{t`Apply Facility`}</Typography>
                        <BeneficiaryFacilityTypeSelector row={row} onChange={updateHandler} />
                      </>
                    }
                  />
                </ListItem>
              </List>
            </Paper>
          );
        })}
      </Grid>
      <Typography sx={{...styles.label, color: 'red', paddingBottom: 0, pt: 0.5}} variant={'body2'}>
        {error?.message}
      </Typography>
    </>
  );
};

const styles = {
  title: {
    fontFamily: 'OpenSansMedium',
    fontSize: '0.875rem',
    color: '#8295AD'
  },
  odd: {
    backgroundColor: 'white',
    borderBottom: '1px solid #EAECF0'
  },
  listItem: {
    flexGrow: 1,
    width: '50%',
    color: '#8295AD',
    '>p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  label: {
    paddingBottom: 0.5,
    color: 'secondary.dark',
    fontWeight: '300'
  }
};

export default BeneficiariesListMobile;
