// @ts-nocheck
import React from 'react';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import { t } from 'ttag';
import { useDeleteRoleMutation } from '../../../../store/apis/ccs';
import PropTypes from 'prop-types';
import {enqueueSnackbar} from "notistack";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import {Cancel} from "@mui/icons-material";

const DeleteRoleDialog = ({ role, open, onClose }) => {
  const [deleteRole, { data, error, isLoading }] =
    useDeleteRoleMutation();

  return (
    <>
      <RTKQueryFeedback
        data={data}
        error={error}
        isLoading={isLoading}
      />
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Cancel color={'error'}/>
          <Typography variant='h2' color={'error'} sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>
            {t`Remove Role`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Grid spacing={3} container>
            <Grid xs={12} item>
              <Typography>
                {t`Do you really want to remove this role?`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display='flex' mx={1} gap={2}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={() =>
                deleteRole(role).then((r) => {
                    if (!r.error) {
                      enqueueSnackbar(t`The role` + ` ${role?.name} ` + t`was successfully removed!`, {variant: 'success'});
                      onClose();
                    }})
              }
            >
              {t`Delete`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteRoleDialog.propTypes = {
  open: PropTypes.bool.isRequired
};

DeleteRoleDialog.defaultProps = {
  open: true
};

export default DeleteRoleDialog;
