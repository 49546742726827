import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {AuthenticationGuard} from "./guards";
import AppInitProvider from "./providers/AppInitProvider";
import Page from './scenes/page/Page';
import Dashboard, {Refunds, Settings} from "./scenes";
import {Forbidden, NotFound, Unauthorized} from "@components/errors";
import {CreateRefundPage} from "./scenes/refunds/refund-forms";
import ViewRefundPage from './scenes/refunds/refund-forms/view-refund-form/ViewRefundPage';
import ResidentValidatorPage from "./scenes/resident-validator";
import {TransportFacilities, TransportFacilitiesCreate} from "./transport-facilities/pages";
import {ROLES} from "./utils/user/user-utils";
import {Login, Logout} from "./scenes/common/authentication";
import ClaimSearch from './scenes/claims/ClaimSearch';
//import ReceiptRequest from "./scenes/receipt-request/ReceiptRequest";

export const App = () => {

  return (
    <BrowserRouter>
      <AuthenticationGuard>
        <AppInitProvider/>
      </AuthenticationGuard>
      <Routes>
        <Route path={'/'} element={<Page><Dashboard/></Page>}/>
        <Route path={'/dashboard'} element={<Navigate to={'/'}/>}/>
        <Route path={'/refunds'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}><Refunds/></Page>}/>
        <Route path={'/refunds/create'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}><CreateRefundPage/></Page>}/>
        <Route path={'/refunds/:refundId'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}><ViewRefundPage/></Page>}/>
        {/*<Route path={'/receipt-request'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}><ReceiptRequest/></Page>}/>*/}
        <Route path={'/claims'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_SUPERVISOR, ROLES.CCS_AGENT]}><ClaimSearch/></Page>}/>
        <Route path={'/resident-validator'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_RESIDENT_VALIDATOR, ROLES.CCS_AGENT, ROLES.CCS_SUPERVISOR]}><ResidentValidatorPage/></Page>}/>
        <Route path={'/transport-facilities'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_AGENT, ROLES.CCS_SUPERVISOR]}><TransportFacilities/></Page>}/>
        <Route path={'/transport-facilities/create'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN, ROLES.CCS_AGENT, ROLES.CCS_SUPERVISOR]}><TransportFacilitiesCreate/></Page>}/>
        <Route path={'/settings'} element={<Navigate to='/settings/employees'/>}/>
        <Route path={'/settings/*'} element={<Page userHasSomeOf={[ROLES.CCS_ADMIN]}><Settings/></Page>}/>
        <Route path={'/401'} element={<Unauthorized/>}/>
        <Route path={'/403'} element={<Forbidden/>}/>
        <Route path={'/404'} element={<NotFound/>}/>
        <Route path={'/auth'} element={<Login/>}/>
        <Route path={'/logout'} element={<Logout/>}/>
        <Route path={'*'} element={<Navigate to='/404'/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
