import React from 'react';
import {t} from 'ttag';
import PropTypes from 'prop-types';
import LabeledDetail from '../../../../../components/labeled-detail';
import {Card, CardContent, CardHeader, Divider, Grid, Typography} from '@mui/material';

interface IReceiptRefundViewProps {
  recipient: {
      name: string;
      contact: string;
      email: string;
      identificationNumber: string;
      documentType: string;
      documentId: string;
      postalCode: string;
      address: string;
      iban: string;
  };
  isLoading: boolean;
}

const RecipientRefundView = ({ recipient, isLoading }: IReceiptRefundViewProps) => {
  const {
    name,
    contact,
    email,
    identificationNumber,
    documentType,
    documentId,
    postalCode,
    address,
    iban
  } = recipient;

  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography sx={{fontFamily: 'OpenSansMedium'}}>{t`Recipient`}</Typography>}
      />
      <Divider/>
      <CardContent>
        <Grid container rowSpacing={2}>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Name`}
              children={name}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Contact`}
              children={contact}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Email`}
              children={email}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`NIF`}
              children={identificationNumber}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Document Type`}
              children={documentType}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Document ID`}
              children={documentId}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Postal Code`}
              children={postalCode}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`Address`}
              children={address}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={4}>
            <LabeledDetail
              label={t`IBAN`}
              children={iban}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

RecipientRefundView.propTypes = {
  recipient: PropTypes.shape({
    name: PropTypes.string,
    contact: PropTypes.string,
    email: PropTypes.string,
    identificationNumber: PropTypes.string,
    documentType: PropTypes.string,
    documentId: PropTypes.string,
    postalCode: PropTypes.string,
    address: PropTypes.string,
    iban: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool
};

RecipientRefundView.defaultProps = {
  recipient: {
    name: '',
    contact: '',
    email: '',
    identificationNumber: '',
    documentType: '',
    documentId: '',
    postalCode: '',
    address: '',
    iban: ''
  },
  isLoading: false
};

export default RecipientRefundView;
