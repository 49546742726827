// @ts-nocheck
import { t } from 'ttag';
import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

import PrintRefund from './refund-export/PrintRefund';
import LabeledDetail from '../../../../../components/labeled-detail';
import SendByEmailRefund from './refund-export/SendByEmailRefund';

const InfoRefundView = ({ refund, isLoading }) => {
  const {
    processId,
    documentNumber,
    type,
    total,
    currency,
    refundId,
    reason,
    state
  } = refund;
  const isProcessed = state === 'PROCESSED';

  return (
    <Card elevation={3}>
      <CardContent>
        <Grid container>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <LabeledDetail
              label={t`Process ID`}
              children={processId}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <LabeledDetail
              label={t`Document Refund ID`}
              children={documentNumber}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <LabeledDetail
              label={t`Type`}
              children={type}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <LabeledDetail
              label={t`Total refund`}
              children={String(total?.toFixed(2))}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <LabeledDetail
              label={t`Currency`}
              children={currency}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </CardContent>
      {(isProcessed || reason) && <Divider />}
      <CardActions>
        <Grid container>
          {reason && (
            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                <strong style={{fontFamily: 'OpenSansMedium'}}>{t`Reason: `}</strong>
                {reason}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} display={'flex'} gap={1} mt={1}>
            {isProcessed && <SendByEmailRefund refundId={refundId} />}
            {isProcessed && <PrintRefund refundId={refundId} />}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

InfoRefundView.propTypes = {
  refund: PropTypes.object,
  isLoading: PropTypes.bool
};

InfoRefundView.defaultProps = {
  refund: {},
  isLoading: false
};

export default InfoRefundView;
