import React, {useEffect, useMemo} from 'react';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import {calculateTotalRefundSubject} from '../../../common/helpers';
import {useFormContext, useWatch} from 'react-hook-form';
import {t} from 'ttag';
import Amount from './Amount';

const RefundSubjectTotal = () => {

  const {setValue, getValues} = useFormContext();
  const [currencyCode, amounts, taxes] = useWatch({name: ['currencyCode', 'amounts', 'taxes']});

  const totalRefund = useMemo(() => {
    return calculateTotalRefundSubject({amounts, taxes});
  }, [amounts, taxes]);

  useEffect(() => {
    setValue('totalRefund', parseFloat(totalRefund), {shouldValidate: true});
  }, [totalRefund, setValue]);

  // @ts-ignore
  return <Amount value={totalRefund} currency={currencyCode}/>;
};

export const RefundSubjectTotalCard = () => {
  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography sx={{fontFamily: 'OpenSansMedium'}}>{t`Total refund`}</Typography>}
      />
      <Divider/>
      <CardContent sx={{fontFamily: 'OpenSansRegular'}}>
        <RefundSubjectTotal/>
      </CardContent>
    </Card>
  );
}

export default RefundSubjectTotal;
