// @ts-nocheck
import React from 'react';
import {
  Card,
  CardHeader, Divider,
  List,
  ListItem,
  ListItemText, Typography
} from '@mui/material';
import { t } from 'ttag';
import PropTypes from 'prop-types';
import History from '../../../../../components/history';
import HistorySkeleton from '../../../../../components/history/HistorySkeleton';
import { useGetRefundHistoryQuery } from '../../../../../store/apis/ccs';

const HistoryRefundView = ({ refundId }) => {
  const { data = [], isLoading } = useGetRefundHistoryQuery(refundId);

  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography sx={{fontFamily: 'OpenSansMedium'}}>{t`History`}</Typography>}
      />
      <Divider />
      <List>
        {!isLoading && !data?.length && (
          <ListItem>
            <ListItemText primary={<>{t`No History`}</>} />
          </ListItem>
        )}
        {data?.map((e, key) => (
          <History
            key={key}
            name={e.employeeName}
            date={e.date}
            state={e.state}
          />
        ))}
        {isLoading && [
          <HistorySkeleton key={0} />,
          <HistorySkeleton key={1} />
        ]}
      </List>
    </Card>
  );
};

HistoryRefundView.propTypes = {
  history: PropTypes.array
};

HistoryRefundView.defaultProps = {
  history: [],
  loading: true
};

export default HistoryRefundView;
