// @ts-nocheck
import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import CreateOfficeFormDialog from './office-forms/CreateOfficeFormDialog';
import EditOfficeFormDialog from './office-forms/EditOfficeFormDialog';
import DeleteOfficeDialog from './office-forms/DeleteOfficeDialog';
import OfficesList from './OfficesList';
import {Button, Card, CardContent, CardHeader, Divider, Paper, Typography} from '@mui/material';

const Offices = () => {

  const [officeToCreate, setOfficeToCreate] = useState(null);
  const [officeToEdit, setOfficeToEdit] = useState(null);
  const [officeToDelete, setOfficeToDelete] = useState(null);


  return (
    <>
      <Card elevation={3}>
        <CardHeader
          sx={{pl: 2, pr: 3}}
          title={<Typography sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}} variant={'h2'}>{t`Offices`}</Typography>}
          action={
            <Button
              variant={'contained'}
              onClick={() => setOfficeToCreate({active: true})}
              startIcon={<Add/>}
              sx={{fontFamily: 'OpenSansMedium'}}
            >
              {t`NEW`}
            </Button>
        }
        />
        <Divider/>
        <CardContent>
          <OfficesList
            editHandler={setOfficeToEdit}
            removeHandler={setOfficeToDelete}
          />
        </CardContent>
        {officeToCreate && (
          <CreateOfficeFormDialog
            office={officeToCreate}
            onClose={() => setOfficeToCreate(null)} open={undefined}        />
        )}
        {officeToEdit && (
          <EditOfficeFormDialog
            office={officeToEdit}
            onClose={() => setOfficeToEdit(null)} open={undefined}        />
        )}
        {officeToDelete && (
          <DeleteOfficeDialog
            office={officeToDelete}
            onClose={() => setOfficeToDelete(null)} open={undefined}        />
        )}
      </Card>
    </>
  );
};

export default Offices;
