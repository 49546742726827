// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {useResidentValidateMutation} from "../../store/apis/ccs";
import {validateNIFs} from "./utils";
import RTKQueryFeedback from "../../components/common/RTKQueryFeedback";
import {
  Button,
  CardHeader,
  TextField,
  Typography,
  CardActions,
  Paper,
  Divider,
  Box, IconButton
} from "@mui/material";
import {useAccount} from "@azure/msal-react";
import {t} from 'ttag';
import {enqueueSnackbar} from "notistack";
import {Add, Reply} from "@mui/icons-material";
import {Check, Close, DeleteOutlined} from "@mui/icons-material";

const ResidentValidator = () => {
  const [residentValidate, {data, error, isLoading, isSuccess}] = useResidentValidateMutation();
  const account = useAccount();

  const [textInputValue, setTextInputValue] = useState('');
  const [listOfVatsToValidate, setListOfVatsToValidate] = useState([]);
  const [helperText, setHelperText] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (isSuccess) setHasSubmitted(true);
  }, [isSuccess]);

  const handleSubmitRequest = () => {
    residentValidate({
      agent: account?.name,
      validations: listOfVatsToValidate.map(vat => ({taxpayerNumber: vat}))
    }).then((response: {data?: any; error?: Error}) => {
      if (response?.error) {
        console.error(response.error);
        return;
      }
      if (!response?.error) {
        enqueueSnackbar(t`VAT validation successfully made!`, {variant: 'success'});
      }
    });
  };

  const onResetButtonClick = () => {
    setHasSubmitted(false);
    setListOfVatsToValidate([]);
  };

  const clearFile = (e, vatIdx) => {
    e.preventDefault();
    e.stopPropagation();

    listOfVatsToValidate.splice(vatIdx, 1)
    setListOfVatsToValidate([...listOfVatsToValidate]);
  };

  const onAddHandler = () => {
    let nifList = [];
    if (listOfVatsToValidate.includes(textInputValue)) return setHelperText('The introduced VAT is already added to the list');
    if ((nifList = validateNIFs(textInputValue))) {
      setListOfVatsToValidate([...new Set([...listOfVatsToValidate, ...nifList])]);
      setTextInputValue('');
      setHelperText('');
    } else {
      setHelperText('The introduced VAT is invalid');
    }
  };

  return (
    <>
      <RTKQueryFeedback error={error} isLoading={isLoading}/>
      <Paper elevation={3} sx={{p: 2}}>
        <CardHeader
          sx={{p: 0, pr: 1}}
          title={<Typography sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>{t`Validation of Resident in the Azores`}</Typography>}
          subheader={<Typography color={'text.secondary'} variant={"subtitle2"}>{t`This tool allows you to validate if a given portuguese taxpayer number has its home address in the Azores archipelago and help you issuing the new Azores Fare.`}</Typography>}
        />
        <Divider sx={{my: 2}}/>
        <Box display={'flex'} gap={2}>
          <TextField
            label={'VAT'}
            value={textInputValue}
            onChange={(e) => setTextInputValue(e.target.value.replace(',,', ','))}
            onKeyDown={e => e.key === 'Enter' && onAddHandler()}
            helperText={helperText}
            error={Boolean(helperText)}
            disabled={hasSubmitted || isLoading}
            size={'small'}
            fullWidth
          />
          {!hasSubmitted &&
            <Button
              variant={'outlined'}
              onClick={onAddHandler}
              disabled={isLoading}
              startIcon={<Add/>}
              sx={{ height: '40px', maxHeight: '40px' }}
            >
              {t`Add`}
            </Button>
          }
        </Box>
          {(listOfVatsToValidate.length > 0) &&
            <Box mt={2}>
              <Typography variant={'body2'} pb={0.5} color={'text.secondary'}>{t`List of VATs to validate:`}</Typography>
              <Box
                sx={{
                  backgroundColor: 'rgba(0, 129, 186, 0.075)',
                  border: '1px solid lightgray',
                  borderRadius: 1,
                  borderBottom: 'none',
                  minWidth: '250px'
                }}
              >
                {!hasSubmitted ? listOfVatsToValidate?.map((vat, idx) => (
                    <Box p={0.75} display={'flex'} alignItems={'center'} gap={0.25} sx={{borderBottom: '1px solid lightgray', borderRadius: idx === listOfVatsToValidate.length - 1 ? 1 : 0}}>
                      <IconButton size={'small'} onClick={e => clearFile(e, idx)}>
                        <DeleteOutlined/>
                      </IconButton>
                      <Typography mt={0.25}>{vat}</Typography>
                    </Box>
                  ))
                  : data?.validations?.map((validation, idx) => (
                    <Box p={0.5} gap={0.5} sx={{borderBottom: '1px solid lightgray', borderRadius: idx === listOfVatsToValidate.length - 1 ? 1 : 0}}>
                      <Box display={'flex'} alignItems={'center'} gap={0.25}>
                        {validation.valid ? <Check color={'success'}/> : <Close color={'error'}/>}
                        <Typography mt={0.25}>{validation.taxpayerNumber}</Typography>
                      </Box>
                      {validation.observations && <Typography variant={'body2'} color={'text.secondary'}>{'(' + validation.observations + ')'}</Typography>}
                    </Box>
                  ))
                }
              </Box>
            </Box>
          }
        <Divider sx={{mx: -2, my: 2}}/>
        <CardActions sx={{display: 'flex', justifyContent: 'flex-end', p: 0}}>
          {!hasSubmitted
            ? <Button variant={'contained'} color={"primary"} onClick={handleSubmitRequest} startIcon={<Check/>} disabled={isLoading || listOfVatsToValidate.length <= 0}>{t`Validate`}</Button>
            : <Button variant={'outlined'} onClick={onResetButtonClick} startIcon={<Reply/>}>{t`Reset`}</Button>
          }
        </CardActions>
      </Paper>
    </>
  );
};

export default ResidentValidator;
