// @ts-nocheck
import {t} from 'ttag';
import React, {useState} from 'react';
import {CloudDownload} from '@mui/icons-material';
import {EmployeesList} from './index';
import {DeleteEmployeeDialog, EditEmployeeDialog, ImportEmployeeDialog} from './employee-forms';
import {Button, Card, CardContent, CardHeader, Divider, Paper, Typography} from '@mui/material';

const Employees = () => {
  const [employeeToEdit, setEmployeeToEdit] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(false);
  const [employeeToImport, setEmployeeToImport] = useState(false);

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          sx={{pl: 2, pr: 3}}
          title={
            <Typography
              sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}
              variant={'h2'}
            >{t`Employees`}</Typography>
          }
          action={
            <Button
              color={'primary'}
              variant={'contained'}
              sx={{fontFamily: 'OpenSansMedium'}}
              onClick={() => setEmployeeToImport(true)}
              startIcon={<CloudDownload />}
            >
              {t`IMPORT`}
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <EmployeesList editHandler={setEmployeeToEdit} removeHandler={setEmployeeToDelete} />
        </CardContent>
      </Card>
      {employeeToEdit && <EditEmployeeDialog onClose={() => setEmployeeToEdit(false)} employee={employeeToEdit} />}

      {employeeToImport && <ImportEmployeeDialog onClose={() => setEmployeeToImport(false)} />}

      {employeeToDelete && (
        <DeleteEmployeeDialog onClose={() => setEmployeeToDelete(false)} employee={employeeToDelete} />
      )}
    </>
  );
};

Employees.propTypes = {};

Employees.defaultProps = {};

export default Employees;
