// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {validationSchema} from './validationSchema';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

const RefundSubjectFormDialogProvider = ({refundSubject, currencyCode, children, editable}) => {
  const poImagineIndex = refundSubject.paymentOptions.findIndex((po) => po.indicator === 'VC555');
  const rs =
    editable || poImagineIndex === -1
      ? refundSubject
      : {
          ...refundSubject,
          paymentOptions: refundSubject.paymentOptions[poImagineIndex + 1]
            ? refundSubject.paymentOptions.map((po, idx) =>
                idx === poImagineIndex + 1
                  ? {...po, currentValue: Number(po.currentValue || 0) - Number(refundSubject.taxes.find((t) => t.taxCode === 'XP')?.value || 0)}
                  : po
              )
            : refundSubject.paymentOptions
        };

  const methods = useForm({
    defaultValues: {currencyCode, totalRefund: 0, ...rs},
    resolver: yupResolver(validationSchema)
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

RefundSubjectFormDialogProvider.propTypes = {
  refundSubject: PropTypes.shape({}),
  currencyCode: PropTypes.string,
  editable: PropTypes.bool
};

RefundSubjectFormDialogProvider.defaultProps = {
  refundSubject: null,
  currencyCode: null,
  editable: false
};

export default RefundSubjectFormDialogProvider;
