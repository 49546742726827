// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {useDeleteRefundMutation} from '../../../store/apis/ccs';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import RTKQueryFeedback from '../../../components/common/RTKQueryFeedback';
import PropTypes from 'prop-types';
import {Cancel} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

const DeleteRefundDialog = ({refund, open, onClose, ...rest}) => {

  const [deleteRefund, {data, error, isLoading}] = useDeleteRefundMutation();

  return (
    <>
      <RTKQueryFeedback error={error} isLoading={isLoading} />
      <Dialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth {...rest}>
        <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Cancel color={'error'}/>
          <Typography variant='h2' color={'error'} sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>
            {t`Delete Refund`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t`Do you really want to delete this refund?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box display='flex' mx={1} gap={2}>
            <Button variant='outlined' onClick={onClose}>
              {t`Cancel`}
            </Button>
            <Button
              variant={'contained'}
              color={'error'}
              onClick={() => deleteRefund(refund).then(r => {
                if (!r.error) {
                  enqueueSnackbar(t`The refund` + ` ${refund?.processId} ` + t`was successfully deleted!`, {variant: 'success'});
                  onClose();
                }
              })}
              >
              {t`Delete`}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteRefundDialog.propTypes = {
  refund: PropTypes.shape({
    refundId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteRefundDialog.defaultProps = {
  refund: null,
  open: true,
  onClose: null,
};

export default DeleteRefundDialog;
