// @ts-nocheck
import {t} from 'ttag';
import React from 'react';
import PropTypes from 'prop-types';
import {useGetRefundCommentsQuery} from '../../../../../store/apis/ccs';
import {Comment, CommentSkeleton} from '../../../../../components/comment';
import {Card, CardHeader, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";

const CommentsRefundView = ({ refundId }) => {
  const { data = [], isLoading } = useGetRefundCommentsQuery(refundId);

  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography sx={{fontFamily: 'OpenSansMedium'}}>{t`Comments`}</Typography>}
        /*action={<Button color="primary" variant="contained" startIcon={<Add />} size="small">{t`Add`}</Button>}*/
      />
      <Divider/>
      <List>
        {!isLoading && !data?.length && (
          <ListItem >
            <ListItemText primary={<>{t`No comments`}</>} />
          </ListItem>
        )}
        {data?.map(({ employeeName, date, comment }, idx) => (
          <Comment
            key={idx}
            name={employeeName}
            date={date}
            comment={comment}
          />
        ))}
        {isLoading && [<CommentSkeleton key={0}/>, <CommentSkeleton key={1}/>]}
      </List>
    </Card>
  );
};

CommentsRefundView.propTypes = {
  comments: PropTypes.array,
  loading: PropTypes.bool.isRequired
};

CommentsRefundView.defaultProps = {
  comments: [],
  loading: true
};

export default CommentsRefundView;
