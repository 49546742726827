// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import {
  AttachmentsRefundView,
  CommentsRefundView,
  HistoryRefundView,
  InfoRefundView,
  MaskRefundView,
  ReceiptsRefundView,
  SubjectsRefundView
} from './index';
import Status from '../../../../components/status';
import { useGetRefundQuery } from '../../../../store/apis/ccs';
import CloseRefund from './view-refund/refund-actions/CloseRefund';
import CancelRefund from './view-refund/refund-actions/CancelRefund';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {useParams} from "react-router";
import {Box, CardActions, CardHeader, Divider, Grid, Paper, Typography} from "@mui/material";

const ViewRefundPage = () => {
  const { refundId } = useParams();
  const { data, error, isLoading } = useGetRefundQuery(refundId);
  const [recipient] = data?.recipients || [];
  const isProcessed = data?.state === 'PROCESSED';
  const isCanceled = data?.state === 'CANCELED';

  return (
    <>
      <Paper elevation={3}>
        <CardHeader
          sx={{mr: 1}}
          title={<Typography sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>{t`Refund`}</Typography>}
          action={
            <Box sx={{ display: 'flex' }}>
              <Status state={data?.state} />
            </Box>
          }
        />
        <Divider />
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Box pt={2}>
              <InfoRefundView refund={data} isLoading={isLoading} />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <Box py={2}>
              <SubjectsRefundView
                refundSubjects={data?.refundSubjects || []}
                currency={data?.currency}
                isLoading={isLoading}
              />
            </Box>
            <Box py={2}>
              <ReceiptsRefundView recipient={recipient} isLoading={isLoading} />
            </Box>
            <Box py={2}>
              <MaskRefundView refund={data} isLoading={isLoading} />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Box py={2}>
              <AttachmentsRefundView
                state={data?.state}
                attachments={data?.attachments}
                refundId={refundId}
                isLoading={isLoading}
              />
            </Box>
            <Box py={2}>
              <CommentsRefundView refundId={refundId} />
            </Box>
            <Box py={2}>
              <HistoryRefundView refundId={refundId} />
            </Box>
          </Grid>
        </Grid>
        <Divider/>
        <CardActions
          sx={{display: 'flex', justifyContent: !isProcessed && isCanceled ? 'flex-end' : 'space-between', p: 2}}
        >
          {!isCanceled && <CancelRefund refundId={refundId} />}
          {!isProcessed && <CloseRefund refundId={refundId} />}
        </CardActions>
      </Paper>
      <RTKQueryFeedback error={error} />
    </>
  );
};

export default ViewRefundPage;
