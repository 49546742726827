// @ts-nocheck
import React, {useState} from 'react';
import FormsOfPaymentList from './FormsOfPaymentList';
import {Add} from '@mui/icons-material';
import CreateFormOfPaymentFormDialog from './forms-of-payment-forms/CreateFormOfPaymentFormDialog';
import EditFormOfPaymentFormDialog from './forms-of-payment-forms/EditFormOfPaymentFormDialog';
import DeleteFormOfPaymentDialog from './forms-of-payment-forms/DeleteFormOfPaymentDialog';
import {t} from 'ttag';
import {Button, Card, CardContent, CardHeader, Divider, Paper, Typography} from '@mui/material';

const FormsOfPayment = () => {
  const [formOfPaymentToCreate, setFormOfPaymentToCreate] = useState(null);
  const [formOfPaymentToEdit, setFormOfPaymentToEdit] = useState(null);
  const [formOfPaymentToDelete, setFormOfPaymentToDelete] = useState(null);

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          sx={{pl: 2, pr: 3}}
          title={
            <Typography
              sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}
              variant={'h2'}
            >{t`Forms of Payment`}</Typography>
          }
          action={
            <Button
              variant={'contained'}
              onClick={() => setFormOfPaymentToCreate({})}
              startIcon={<Add />}
              sx={{fontFamily: 'OpenSansMedium'}}
            >
              {t`NEW`}
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <FormsOfPaymentList editHandler={setFormOfPaymentToEdit} removeHandler={setFormOfPaymentToDelete} />
        </CardContent>
      </Card>
      {formOfPaymentToCreate && (
        <CreateFormOfPaymentFormDialog
          formOfPayment={formOfPaymentToCreate}
          onClose={() => setFormOfPaymentToCreate(null)}
        />
      )}
      {formOfPaymentToEdit && (
        <EditFormOfPaymentFormDialog formOfPayment={formOfPaymentToEdit} onClose={() => setFormOfPaymentToEdit(null)} />
      )}
      {formOfPaymentToDelete && (
        <DeleteFormOfPaymentDialog
          formOfPayment={formOfPaymentToDelete}
          onClose={() => setFormOfPaymentToDelete(null)}
        />
      )}
    </>
  );
};

export default FormsOfPayment;
