// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {useFormik} from 'formik';
import FormOfPaymentForm from './FormOfPaymentForm';
import {formOfPaymentValidationSchema} from './helpers';
import {useEditFormOfPaymentMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";

const EditFormOfPaymentFormDialog = ({formOfPayment, open, onClose, ...rest}) => {

  const [editFormOfPayment, {data, error, isLoading}] = useEditFormOfPaymentMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formOfPayment,
    validationSchema: formOfPaymentValidationSchema,
    onSubmit: values => editFormOfPayment(values).then(r => {
      if (!r.error) {
        enqueueSnackbar(t`The form of payment` + ` ${r?.data?.name} ` + t`was successfully edited!`, {variant: 'success'});
        onClose();
      }
    }),
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <DialogTitle>
        <Typography variant='h2' sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>
          {t`Edit Form of payment`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={1}>
          <FormOfPaymentForm formik={formik}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={.4}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit}>{t`Save`}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EditFormOfPaymentFormDialog.propTypes = {
  formOfPayment: PropTypes.shape({
    formOfPaymentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

EditFormOfPaymentFormDialog.defaultProps = {
  formOfPayment: null,
  open: true,
  onClose: null,
};

export default EditFormOfPaymentFormDialog;
