// @ts-nocheck
import {t} from 'ttag';
import React, {useEffect} from 'react';
import RefundSubjectsList from '../../create-refund-form/step-2/refund-subjects/list/RefundSubjectsList';
import RefundSubjectFormDialogConnected
  from '../../create-refund-form/step-2/refund-subjects/crud-forms/create-edit/RefundSubjectFormDialogConnected';
import {setFormData} from '../../create-refund-form/common/store/form-state';
import CreateRefundFormProvider from '../../create-refund-form/common/CreateRefundFormProvider';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';

const RefundSubjectsView = ({ refundSubjects, currency, isLoading }) => {

  useEffect(() => {
    setFormData({office: {currencyCode: currency}});
  }, [currency]);

  if (!refundSubjects?.length) {
    return null;
  }

  return (
    <Card elevation={3}>
      <CardHeader
        title={<Typography sx={{fontFamily: 'OpenSansMedium'}}>{t`Refund subjects`}</Typography>}
      />
      <Divider/>
      <CardContent>
        <CreateRefundFormProvider>
          <RefundSubjectsList
            isLoading={isLoading}
            refundSubjects={refundSubjects.map((rs, localIdx) => ({
              ...rs,
              localId: localIdx,
              passengers: rs.passengers.map((p, idx) => ({...p, localId: idx})),
              coupons: rs.coupons.map((c, idx) => ({ ...c, localId: idx })),
              amounts: rs.amounts.map((a, idx) => ({ ...a, localId: idx })),
              taxes: rs.taxes.map((tx, idx) => ({ ...tx, localId: idx })),
              paymentOptions: rs.paymentOptions.map((p, idx) => ({...p, localId: idx})),
              currencyCode: currency
            }))}
            editable={false}
          />
          <RefundSubjectFormDialogConnected editable={false}/>
        </CreateRefundFormProvider>
      </CardContent>
    </Card>
  );
};

RefundSubjectsView.propTypes = {};

RefundSubjectsView.defaultProps = {
  refundSubjects: []
};

export default RefundSubjectsView;
