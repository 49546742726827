import React from 'react';
import {t} from 'ttag';
import {useLocation, useNavigate} from 'react-router-dom';
import {Grid, List, Typography} from '@mui/material';
import {Employees, FormsOfPayment, Offices, Roles} from './index';
import SubMenuSidebarLink from '../../layout/main-layout/SubMenuSidebarLink';

const Settings = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  // To add a new setting, add a new object to this settingsOptions array
  const settingsOptions = [
    {name: t`Employees`, path: 'employees', component: <Employees />},
    {name: t`Roles`, path: 'roles', component: <Roles />},
    {name: t`Offices`, path: 'offices', component: <Offices />},
    {name: t`Forms of Payment`, path: 'forms-of-payment', component: <FormsOfPayment />}
    //{name: t`Issue Offices`, path: 'issue-offices', component: <IssueOffices/>}
  ];

  if (
    !settingsOptions.flatMap((option) => option.path).includes(pathname.split('/')[2]) ||
    pathname.split('/').length >= 4
  ) {
    window.location.assign('/settings');
  }

  return (
    <Grid minHeight={'100%'} spacing={2} container>
      <Grid item xs={12} md={2}>
        <Typography variant={'h2'} sx={styles.title}>{t`Settings`}</Typography>
        <List>
          {settingsOptions.map((option, idx) => (
            <SubMenuSidebarLink
              exact={true}
              itemKey={idx}
              text={option.name}
              path={`/settings/${option.path}`}
            />
          ))}
        </List>
      </Grid>
      <Grid item xs={12} md={10}>
        {settingsOptions.map((option, idx) => pathname === `/settings/${option.path}` && option.component)}
      </Grid>
    </Grid>
  );
};

export const styles = {
  title: {
    color: 'secondary.dark',
    fontSize: '1.3rem',
    fontFamily: 'OpenSansRegular',
    fontWeight: 'bold'
  }
};

export default Settings;
