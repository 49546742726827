// @ts-nocheck
import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import PropTypes from 'prop-types';
import {Box} from "@mui/material";
import {t} from 'ttag';

const ConfirmDialog = ({onConfirm, onCancel, title, children, confirmText, cancelText, open, variant, reverse, ...rest}) => {

  if (!open) return null;

  return (
    <Dialog title={title} variant={variant} open={open} onClose={onCancel} maxWidth={'xs'} fullWidth {...rest}>
      <DialogTitle variant={'h4'}>{t`Delete` + ` ${title}`}</DialogTitle>
      <DialogContent dividers>
        <Box p={1} sx={{fontFamily: 'OpenSansRegular'}}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{display: 'flex', flexDirection: reverse ? 'row-reverse' : 'row'}}>
          <Box mx={1}>
            <Button variant={'outlined'} onClick={onCancel}>
              {cancelText}
            </Button>
          </Box>
          <Box mx={1}>
            <Button variant={'contained'} type={variant} onClick={onConfirm}>
              {confirmText}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  title: PropTypes.string,
  children: PropTypes.any,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  reverse: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  open: true,
  variant: 'error',
  title: 'Confirmation',
  children: 'Are you sure?',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  onConfirm: () => null,
  onCancel: () => null,
  reverse: false,
};

export default ConfirmDialog;
