import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Skeleton, Typography} from '@mui/material';
import {ILabeledRowField} from './labeled-row-field';

export const LabeledRowField = ({label, disableLabel, isLoading, text, textTransform, children}: ILabeledRowField) => {
  return (
    <Grid sx={styles.box} container>
      {!disableLabel && (
        <Grid xs={6} sx={{display: 'flex'}} item>
          <Typography sx={styles.label}>{label}</Typography>
        </Grid>
      )}
      <Grid xs={!disableLabel ? 6 : 12} item>
        {!isLoading ? (
          text ? (
            <Typography sx={styles.text(textTransform || 'initial')}>{text}</Typography>
          ) : (
            children || '-'
          )
        ) : (
          <Skeleton sx={{width: '80%'}} />
        )}
      </Grid>
    </Grid>
  );
};

const styles = {
  box: {
    width: '100%'
  },
  label: {
    color: '#98A2B3',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontFamily: 'OpenSansBold',
    my: 'auto'
  },
  text: (textTransform: string) => ({
    color: '#1D2939',
    fontSize: '1rem',
    textTransform,
    fontFamily: 'OpenSansRegular',
    overflowWrap: 'break-word',
    wordWrap: ' break-word',
    hyphens: 'auto'
  })
};

LabeledRowField.propTypes = {
  label: PropTypes.string,
  disableLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
  textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  children: PropTypes.any
};

LabeledRowField.defaultProps = {
  label: 'PAÍS DE NASCIMENTO',
  disableLabel: false,
  isLoading: false,
  textTransform: 'capitalize',
  children: null
};

export default LabeledRowField;
