import React, { useMemo } from 'react';
import { Help, Done, Update, Cancel } from '@mui/icons-material';
import {Chip} from '@mui/material';

export const STATUSES = {
  PENDING: {
    name: 'PENDING',
    icon: <Update fontSize='small' />,
    severity: 'warning'
  },
  CANCELED: {
    name: 'CANCELED',
    icon: <Cancel fontSize='small' />,
    severity: 'error'
  },
  PROCESSED: {
    name: 'PROCESSED',
    icon: <Done fontSize='small' />,
    severity: 'success'
  },
  UNKNOWN: {
    name: 'UNKNOWN',
    icon: <Help fontSize='small' />,
    severity: 'info'
  }
};

interface StatusProps {
  state: string;
  size?: 'small' | 'medium';
  fullwidth?: boolean;
}

const Status = ({ state, size = 'medium', fullwidth = false }: StatusProps) => {
  const status = useMemo(() => {
    if (STATUSES.hasOwnProperty(state?.toUpperCase())) {
      return STATUSES[state?.toUpperCase() as keyof typeof STATUSES];
    }
    return STATUSES.UNKNOWN;
  }, [state]);

  return (
    <Chip
      icon={status.icon}
      size={size}
      color={status.severity as 'info' | 'success' | 'error' | 'warning' | 'default' | 'primary' | 'secondary'}
      label={`${status.name}`}
      sx={{
        pl: '2px',
        width: fullwidth ? '100%' : 'auto',
        textTransform: 'capitalize',
        textAlign: 'center',
      }}
    />
  );
};

export default Status;
