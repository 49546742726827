// @ts-nocheck
import React, {useMemo, useState} from 'react';
import { useDownloadAttachmentsMutation } from '../../../../../../store/apis/ccs';
import { CloudDownload } from '@mui/icons-material';
import RTKQueryFeedback from '../../../../../../components/common/RTKQueryFeedback';
import {IconButton} from "@mui/material";

const AttachmentsDownloads = ({ attachments, refundId }) => {
  const [downloadAttachments, { data, error, isLoading }] =
    useDownloadAttachmentsMutation();
  const [fileName, setFileName] = useState();

  useMemo(() => {
    if (data) {
      const file = new Blob([data], { type: data.type });
      const fileURL = URL.createObjectURL(file);
      if (data.type === 'text/plain' || data.type === 'application/msword' || data.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = fileName;
        return a.click();
      }
      window.open(fileURL);
    }
  }, [data, fileName]);

  return (
    <>
      {attachments && attachments?.map((attach) => (
        <span key={attach.refundAttachmentId} style={{fontFamily: 'OpenSansRegular', fontSize: '0.925rem'}}>
          <IconButton
            size={'small'}
            sx={{mr: 0.25}}
            onClick={() => {
              setFileName(attach.name);
              downloadAttachments({
                refundId,
                attachmentId: attach.refundAttachmentId,
                path: attach.location
              })
            }
          }
          >
            <CloudDownload />
          </IconButton>
          <b>{attach.name}</b>
          <br />
        </span>
      ))}
      <RTKQueryFeedback error={error} isLoading={isLoading} />
    </>
  );
};

AttachmentsDownloads.propTypes = {};

AttachmentsDownloads.defaultProps = {};

export default AttachmentsDownloads;
