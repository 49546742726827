// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {useFormik} from 'formik';
import FormOfPaymentForm from './FormOfPaymentForm';
import {formOfPaymentValidationSchema} from './helpers';
import {useCreateFormOfPaymentMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {enqueueSnackbar} from "notistack";

const CreateFormOfPaymentFormDialog = ({formOfPayment, title, open, onClose, ...rest}) => {

  const [createFormOfPayment, {data, error, isLoading}] = useCreateFormOfPaymentMutation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formOfPayment,
    validationSchema: formOfPaymentValidationSchema,
    onSubmit: (values) => createFormOfPayment(values).then(r => {
      if (!r.error) {
        onClose();
        enqueueSnackbar(t`The form of payment` + ` ${r?.data?.name} ` + t`was successfully created!`, {variant: 'success'});
      }
    }),
  });

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <DialogTitle>
        <Typography variant='h2' sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>
          {t`New Form of payment`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <FormOfPaymentForm formik={formik}/>
      </DialogContent>
      <DialogActions>
        <Box p={1} px={.4}>
          <Button variant={'outlined'} onClick={onClose} m={3}>{t`Cancel`}</Button>
        </Box>
        <Box p={1} px={.4}>
          <Button variant={'contained'} onClick={formik.handleSubmit} m={3}>{t`Create`}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CreateFormOfPaymentFormDialog.propTypes = {
  formOfPayment: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateFormOfPaymentFormDialog.defaultProps = {
  formOfPayment: null,
  open: true,
  onClose: null,
};

export default CreateFormOfPaymentFormDialog;
