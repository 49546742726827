import React from 'react';
import {useMatch, useNavigate} from 'react-router-dom';
import {ListItem, ListItemIcon, ListItemText, Theme} from '@mui/material';

type SubMenuSidebarLinkProps = {
    path: string,
    text: string,
    itemKey: number | string,
    exact: boolean
    icon?: React.ReactNode
}

export const SubMenuSidebarLink = ({path, text, itemKey, exact, icon = undefined}: SubMenuSidebarLinkProps) => {
  const navigate = useNavigate();
  const matches = useMatch({path, end: exact});

  const clickHandler = () => {
    navigate(path);
  };

  return (
    <ListItem key={itemKey} onClick={clickHandler} sx={matches ? styles.activeButton : styles.button} >
      {icon ? <ListItemIcon sx={{minWidth: 30}}>{icon}</ListItemIcon> : null}
      <ListItemText primary={text} primaryTypographyProps={{sx: styles.text}}/>
    </ListItem>
  );
};

const styles = {
  button:{
    paddingLeft: '1.75rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeButton: {
    backgroundColor: '#F3F4F7',
    borderLeft: '0.250rem solid blue',
    borderLeftColor: (theme: Theme) => theme.palette.primary.main,
    paddingLeft: '1.5rem',
    '&:hover': {
      backgroundColor: '#F3F4F7',
      cursor: 'pointer'
    }
  },
  text: {
    color: 'secondary.main',
    fontSize: '1rem',
    fontFamily: 'OpenSansRegular',
    textTransform: 'capitalize',
  }
};

export default SubMenuSidebarLink;
