// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {useDeleteFormOfPaymentMutation} from '../../../../store/apis/ccs';
import RTKQueryFeedback from '../../../../components/common/RTKQueryFeedback';
import PropTypes from 'prop-types';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {Cancel} from "@mui/icons-material";
import {enqueueSnackbar} from "notistack";

const DeleteFormOfPaymentDialog = ({formOfPayment, open, onClose, ...rest}) => {

  const [deleteFormOfPayment, {data, error, isLoading}] = useDeleteFormOfPaymentMutation();

  return (
    <>
      <RTKQueryFeedback data={data} error={error} isLoading={isLoading}/>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'xs'}
      >
        <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
          <Cancel color={'error'}/>
          <Typography variant='h2' color={'error'} sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>
            {t`Remove Form of payment`}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t`Do you really want to remove this form of payment?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Box display='flex' mx={1} gap={2}>
            <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
            <Button variant={'contained'} color={'error'} onClick={() => deleteFormOfPayment(formOfPayment).then(r => {
              if (!r.error) {
                enqueueSnackbar(t`The form of payment` + ` ${formOfPayment?.name} ` + `was successfully removed!`, {variant: 'success'});
                onClose();
              }
            })}>{t`Delete`}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

DeleteFormOfPaymentDialog.propTypes = {
  formOfPayment: PropTypes.shape({
    formOfPaymentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteFormOfPaymentDialog.defaultProps = {
  formOfPayment: null,
  open: true,
  onClose: null,
};

export default DeleteFormOfPaymentDialog;
