// @ts-nocheck
import {authAxiosInstance, mockResolver} from './auth-axios-instance';
import AxiosError from './AxiosError';
import {API_MOCK} from '../config/settings';

export const axiosBaseQuery = ({baseUrl} = {baseUrl: ''}) => {
  return async ({url = '', method, body: data, destructFn = (d) => d.data, ...rest}) => {
    try {
      if (API_MOCK) return {data: destructFn(mockResolver({method, url}))};
      const result = await authAxiosInstance({url: baseUrl + url, method, data, ...rest});
      return {data: destructFn(result)};
    } catch (e: any) {
      if (Date.now() > (JSON.parse(atob(localStorage.getItem('accessToken').split('.')[1])).exp * 1000) - 100) {
        window.location.href = '/auth';
      }
      if (!e?.response) {
        window.location.href = '/auth';
      }
      if (e?.response?.status === 401) {
        window.location.href = '/401';
      }
      const message =
        (e?.response?.data?.description
          ? e?.response?.data?.description
          : e?.response?.data?.[Object.keys(e?.response?.data)[0]]) || e?.message;
      throw new AxiosError(message, e?.response?.status, JSON.stringify(e?.response?.data));
    }
  };
};
