// @ts-nocheck
import React from 'react';
import {t} from 'ttag';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {Box} from "@mui/material";
import {Cancel} from "@mui/icons-material";

const DeleteRefundSubjectDialog = ({onDelete, open, onClose, ...rest}) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth {...rest}>
      <DialogTitle sx={{display: 'flex', alignItems: 'flex-start', gap: 1}}>
        <Cancel color={'error'}/>
        <Typography variant='h2' color={'error'} sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}>
          {t`Delete Refund Subject`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          {t`Do you really want to delete this refund subject?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box display='flex' mx={1} gap={2}>
          <Button variant={'outlined'} onClick={onClose}>{t`Cancel`}</Button>
          <Button variant={'contained'} color={'error'} onClick={() => {
            onDelete();
            onClose();
          }}>{t`Delete`}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

DeleteRefundSubjectDialog.propTypes = {
  onDelete: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteRefundSubjectDialog.defaultProps = {
  onDelete: () => null,
  open: true,
  onClose: null,
};

export default DeleteRefundSubjectDialog;
