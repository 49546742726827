// @ts-nocheck
import {t} from 'ttag';
import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {RolesList} from './index';
import {AddRoleDialog, DeleteRoleDialog, EditRoleDialog} from './role-forms';
import {Button, Card, CardContent, CardHeader, Divider, Paper, Typography} from '@mui/material';

const Roles = () => {
  const [roleToAdd, setRoleToAdd] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(false);

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          sx={{pl: 2, pr: 3}}
          title={
            <Typography
              sx={{fontFamily: 'OpenSansMedium', fontSize: '1.375rem'}}
              variant={'h2'}
            >{t`Roles`}</Typography>
          }
          action={
            <Button
              sx={{fontFamily: 'OpenSansMedium'}}
              onClick={() => setRoleToAdd({})}
              startIcon={<Add />}
              variant={'contained'}
            >{t`Add`}</Button>
          }
        />
        <Divider />
        <CardContent>
          <RolesList editHandler={setRoleToEdit} deleteHandler={setRoleToDelete} />
        </CardContent>
      </Card>

      {roleToEdit && <EditRoleDialog onClose={() => setRoleToEdit(false)} role={roleToEdit} />}

      {roleToAdd && <AddRoleDialog role={roleToAdd} onClose={() => setRoleToAdd(false)} />}

      {roleToDelete && <DeleteRoleDialog onClose={() => setRoleToDelete(false)} role={roleToDelete} />}
    </>
  );
};

Roles.propTypes = {};

Roles.defaultProps = {};

export default Roles;
